import * as ko from 'knockout';

import { links } from '../links';
import AuthenticatedUser from '../authenticatedUser';
import AuthenticationClient from '../common/webHostClient/authentication/authenticationClient';
import ConfigurationClient from '../common/webHostClient/configuration/configurationClient';
import GetTokenInfoRequest from '../common/webHostClient/authentication/getTokenInfoRequest';
import GetTokenInfoResponse from '../common/webHostClient/authentication/getTokenInfoResponse';
import storage from '../common/storage';
import globals from '../common/globals';
import '../common/staticFileReferences';
import Feature from '../common/webHostClient/configuration/Feature';

/**
 * Base page view model
 */
export default abstract class BasePage {

    private static wayleavesMapFeatureName  = "WayleavesMapUSOnline";
    public readonly currentUser: ko.Observable<AuthenticatedUser> = ko.observable();
    public readonly links = links;
    protected requiresAuthentication: boolean = false;
    public readonly isWayleavesMapEnabled : ko.Observable<boolean> = ko.observable(false);

    public async initialiseAsync(): Promise<void> {

        // Get site key
        const configurationClient = new ConfigurationClient();
        globals.config = await configurationClient.getGeneralConfigurationAsync();

        if (!await this.preflightAsync())
        {
            console.log("Page preflight checks failed, navigating to home page.");
            window.location.assign(this.links.homeHref);
            return;
        }

        await this.loadHeadAsync();
        await this.loadAuthenticationAsync();

        if (this.requiresAuthentication && this.currentUser() == null)
        {
            console.log("Page requires user to login.");
            const loginHref: string = this.links.getRedirectingBackToCurrentPageLoginHref("login");
            window.location.assign(loginHref);
            return;
        }

        const wayleavesMapFeatureToggle : Feature =  globals.config.features.find(i => i.name === BasePage.wayleavesMapFeatureName)

        this.isWayleavesMapEnabled(wayleavesMapFeatureToggle.enabled);

        await this.loadContentAsync();

        this.defineCustomBindings();

        ko.applyBindings(this);

        await this.onLoadCompleteAsync();
    }

    protected async onLoadCompleteAsync(): Promise<void>  {
        return;
    }

    protected async loadContentAsync(): Promise<void>
    {
        return;
    }

    public signOut() {
        this.clearAllInProgressOrderData();
        storage.clearAuthenticationToken();
        window.location.assign(this.links.homeHref);
    }

    protected async preflightAsync(): Promise<boolean> {
        return true;
    }

    protected getQueryStringParameter(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    }

    /**
     * Clears all in progress order data from persistent storage.
     */
    protected clearAllInProgressOrderData() {
        storage.clearInProgressOrderToken();
        storage.clearPayPalOrderId();
        storage.clearStartOrderLocationQueryText();
        storage.clearStartOrderMapBounds();
        storage.clearStartOrderSiteGeometry();
    }

    private async loadHeadAsync() {

        const headElement = document.getElementsByTagName("head")[0];

        // Google Tag Manager
        const gtmScriptElement: HTMLScriptElement = document.createElement("script");
        gtmScriptElement.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':"
            + "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],"
            + "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src="
            + "'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=" + globals.config.gtmAuth + "&gtm_preview=" + globals.config.gtmPreview + "&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);"
            + "})(window,document,'script','dataLayer','GTM-T7Q28RD');"
        headElement.appendChild(gtmScriptElement);

        // Google reCAPTCHA
        const recaptchaScriptElement: HTMLScriptElement = document.createElement("script");
        recaptchaScriptElement.src = `${globals.config.reCaptchaClientScriptUrl}?render=${globals.config.reCaptchaSiteKey}`;
        
        await new Promise((resolve, reject) => {
            recaptchaScriptElement.addEventListener('load', () => resolve(null));
            recaptchaScriptElement.onerror = (error) => reject(error);
            headElement.appendChild(recaptchaScriptElement);
        });
        
    }

    /**
     * Loads authentication items for the page.
     */
    private async loadAuthenticationAsync() {

        const authenticationClient: AuthenticationClient = new AuthenticationClient();

        const tokenInfoRequest: GetTokenInfoRequest = {
            orderToken: storage.getInProgressOrderToken(),
        };

        const tokenInfoResponse : GetTokenInfoResponse = await authenticationClient.getTokenInfo(tokenInfoRequest);

        // Is the user authenticated?
        if (tokenInfoResponse.currentUserEmail && tokenInfoResponse.currentUserName)
        {
            this.currentUser({
                name: tokenInfoResponse.currentUserName, 
                email: tokenInfoResponse.currentUserEmail,
            });
        }
        else
        {
            storage.clearAuthenticationToken();
            this.currentUser(null);
        }

        // Is there an order in progress?
        if (tokenInfoResponse.orderToken)
        {
            // Yes, hang on to the refreshed token
            storage.setInProgressOrderToken(tokenInfoResponse.orderToken);
        }
        else
        {
            // No, clear any stale token.
            storage.clearInProgressOrderToken();
        }
    }

    private defineCustomBindings() {

        // Because the elements are created by knockout the element doesn't exisits to bind popover to until knockout has finished rendering,
        // Using a databinding to bind popover instead.
        ko.bindingHandlers.bindPopover = {
            init: (element, _valueAccessor, _allBindings, _viewModel, _bindingContext) => {
                $(element).popover();
            }
        };
        
    }
}