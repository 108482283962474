import * as pages from './pages';

// Common source for all hyperlinks within the web app
class Links {
    public readonly homeHref = Links.pageToPath(pages.home);

    public readonly errorHref = Links.pageToPath(pages.error);

    public readonly logInHref = Links.pageToPath(pages.logIn);
    public readonly forgottenPasswordHref = Links.pageToPath(pages.forgottenPassword);

    // Footer
    public readonly accessibilityPolicyHref = 'https://www.atkinsglobal.com/accessibility-policy';
    public readonly privacyNoticeHref = 'https://www.atkinsglobal.com/privacy-policy';
    public readonly modernSlaveryTraffickingStatementHref = 'https://www.atkinsglobal.com/statutory-and-regulatory-disclosures#accordion-item-link-modern-slavery-and-human-trafficking-statement';
    public readonly statutoryRegulatoryDisclosuresHref = 'https://www.atkinsglobal.com/statutory-and-regulatory-disclosures';
    public readonly integrityHref = 'https://www.snclavalin.com/en/about/integrity';
    public readonly reportingLineHref = 'https://www.snclavalin.com/en/site-services/reporting-line';
    public readonly disclaimerHref = 'https://www.atkinsglobal.com/disclaimer';

    public readonly aboutUsHref = Links.pageToPath(pages.aboutUs);
    public readonly aboutUsBestPracticeHref = `${this.aboutUsHref}#bestpractice`;
    public readonly aboutUsMeetTheTeamHref = `${this.aboutUsHref}#meettheteam`;
    public readonly aboutUsOurservicesHref = `${this.aboutUsHref}#ourservices`;

    public readonly whatWeOfferHref = Links.pageToPath(pages.whatWeOffer);
    public readonly productsHref = Links.pageToPath(pages.products);
    public readonly wayleavesMapHref = Links.pageToPath(pages.wayleavesMap);

    // Products Page - Products panels
    public readonly productsUsrHref = `${this.productsHref}#usr-panel`;
    public readonly productsUsmHref = `${this.productsHref}#usm-panel`;
    public readonly productsUssHref = `${this.productsHref}#uss-panel`;

    // What we offer - Discovery phase
    public readonly whatWeOfferDiscoveryHref = `${this.whatWeOfferHref}#discovery`;
    public readonly whatWeOfferUtilitySearchReportHref = `${this.whatWeOfferHref}#utilitysearchreport`;
    public readonly whatWeOfferUtilitySearchMapHref = `${this.whatWeOfferHref}#utilitysearchmap`;
    public readonly whatWeOfferUtilitySearchSummaryHref = `${this.whatWeOfferHref}#utilitysearchsummary`;
    public readonly whatWeOfferWayleavesReportHref = `${this.whatWeOfferHref}#wayleavesreport`;

    // What we offer - Feasibility phase
    public readonly whatWeOfferFeasibilityHref = `${this.whatWeOfferHref}#feasibility`;
    public readonly whatWeOfferDiversionaryWorksBudgetHref = `${this.whatWeOfferHref}#diversionaryworksbudget`;
    public readonly whatWeOfferConstraintsAnalysisHref = `${this.whatWeOfferHref}#constraintsanalysis`;
    public readonly whatWeOfferCapacityAnalysisHref = `${this.whatWeOfferHref}#capacityanalysis`;
    public readonly whatWeOfferNewConnectionsBudgetHref = `${this.whatWeOfferHref}#newconnectionsbudget`;

    // What we offer - Procurement phase
    public readonly whatWeOfferProcurementHref = `${this.whatWeOfferHref}#procurement`;
    public readonly whatWeOfferDiversionaryWorksDetailedHref = `${this.whatWeOfferHref}#diversionaryworksdetailed`;
    public readonly whatWeOfferNewConnectionsFirmHref = `${this.whatWeOfferHref}#newconnectionsfirm`;

    // What we offer - Coordination phase
    public readonly whatWeOfferCoordinationHref = `${this.whatWeOfferHref}#coordination`;
    public readonly whatWeOfferDiversionaryWorksCoordinationHref = `${this.whatWeOfferHref}#diversionaryworkscoordination`;
    public readonly whatWeOfferDisconnectionsHref = `${this.whatWeOfferHref}#disconnections`;
    public readonly whatWeOfferNewconnectionsCoordinationHref = `${this.whatWeOfferHref}#newconnectionscoordination`;

    public readonly pricingHref = Links.pageToPath(pages.pricing);

    // Hashes
    public readonly utilitySearchReportHash = `#utility-search-report`;
    public readonly utilitySearchMapHash = `#utility-search-map`;
    public readonly utilitySearchSummaryHash = `#utility-search-summary`;

    // Pricing - Utility Search Report
    public readonly pricingUtilitySearchReportHref = `${this.pricingHref}${this.utilitySearchReportHash}`;

    // Pricing - Utility Search Map
    public readonly pricingUtilitySearchMapHref = `${this.pricingHref}${this.utilitySearchMapHash}`;

    // Pricing - Utility Search Summary
    public readonly pricingUtilitySearchSummaryHref = `${this.pricingHref}${this.utilitySearchSummaryHash}`;

    public readonly caseStudiesHref = Links.pageToPath(pages.caseStudies);

    // Order process - select area
    public readonly selectAreaHref = Links.pageToPath(pages.selectArea);

    public readonly selectOptionsHref = Links.pageToPath(pages.selectOptions);

    public readonly contactAndHelpHref = Links.pageToPath(pages.contactAndHelp);
    public readonly contactAndHelpEnquiryHref = `${this.contactAndHelpHref}#ums-customer-enquiry`;

    public readonly orderDetailsHref = Links.pageToPath(pages.orderDetails);

    // Order process - view estimate
    public readonly viewEstimateHref = Links.pageToPath(pages.viewEstimate);

    // Order process - checkout on account
    public readonly checkoutHref = Links.pageToPath(pages.checkout);

    // Order process - confirmation (for checkout as guest).
    public readonly confirmationHref = Links.pageToPath(pages.confirmation);

    // Add products page (formally known as retrospective USM).
    public readonly addProductsHref = Links.pageToPath(pages.addProducts);

    public readonly myOrdersHref = Links.pageToPath(pages.myOrders);

    public readonly orderIdQueryStringKey: string = "orderId";
    public readonly actionQueryStringKey: string = "a";
    public readonly actionQueryStringValueRegister: LoginPageActionQueryString = "register";
    public readonly actionQueryStringValueLogin: LoginPageActionQueryString = "login";
    public readonly returnUrlQueryStringKey: string = "returnUrl";
    public readonly filterQueryStringKey: string = "f"
    public readonly filterQueryStringValueAll: string = "a"
    public readonly filterQueryStringValueInProgress: string = "p"
    public readonly filterQueryStringValueIssued: string = "i"
    public readonly filterQueryStringValueExpired: string = "e"
    public readonly filterQueryStringValueCancelled: string = "c"
    public readonly filterQueryStringValueQuote: string = "q"

    public readonly hasPlacedOrderRegisteredForInvoicing: string = "rfi";
    public readonly hasCompletedInlineRegistrationQueryStringKey: string = "reg";
    public readonly queryStringTrueValue: string = "t";
    
    public readonly documentIdQueryStringKey: string = "documentId";

    public readonly myDocumentsHref = Links.pageToPath(pages.myDocuments);
    public readonly myDocumentsDetailsHref = Links.pageToPath(pages.myDocumentDetails);
    public readonly myNotificationsHref = Links.pageToPath(pages.myNotifications);
    public readonly myAccountHref = Links.pageToPath(pages.myAccount);

    public readonly primaryMenuItems: ReadonlyArray<NavItem> = [
        Links.pageToNavItem(pages.aboutUs),
        Links.pageToNavItem(pages.whatWeOffer),
        Links.pageToNavItem(pages.pricing),
        Links.pageToNavItem(pages.caseStudies),
    ];

    public readonly secondaryMenuItems: ReadonlyArray<NavItem> = [
        Links.pageToNavItem(pages.myOrders),
        Links.pageToNavItem(pages.myDocuments),
        Links.pageToNavItem(pages.myNotifications),
        Links.pageToNavItem(pages.myAccount)
    ];

    public getRedirectingBackToCurrentPageLoginHref(action: LoginPageActionQueryString) : string
    {
        // The redirect URL should only contain the path, query string and hash of the current URL.
        const currentUrl = new URL(window.location.href);
        const loginHref: string = this.getLoginHref(action, currentUrl.pathname + currentUrl.search + currentUrl.hash);
        return loginHref;
    }

    public getLoginHref(action: LoginPageActionQueryString, redirectUrl: string) : string
    {

        let fullLoginHref = `${this.logInHref}`;
        let sep = "?";

        if (action)
        {
            fullLoginHref += `${sep}${this.actionQueryStringKey}=${action}`
            sep = "&"
        }

        if (redirectUrl)
        {
            let returnUrlEncoded = encodeURIComponent(redirectUrl);
            fullLoginHref += `${sep}${this.returnUrlQueryStringKey}=${returnUrlEncoded}`;

        }

        return fullLoginHref;
    }

    private static pageToPath(page): string {
        return [""].concat(page.directoryParts).concat(page.htmlName).join("/");
    }

    private static pageToNavItem(page): NavItem {
        const path = Links.pageToPath(page);
        return {
            title: page.title,
            pathOrCallback: path,
            isActive: window.location.pathname === path
        }
    }
}


// Exports

// Singleton instance
export const links = new Links();

// Interfaces
export interface NavItem { title: string; pathOrCallback: string | (() => void); isActive: boolean };

// All possible values for the action query string on the login page. 
export type LoginPageActionQueryString = "login" | "register";